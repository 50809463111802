<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel="close">
			<template #title>
				<modalHeader title="下账申请"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmit" type="primary" v-if="!isShowDetail">提交申请</a-button>
			</template>
			<a-spin :spinning="loading">
			<a-form ref="formRef" :model="formModal" :label-col="{ span: 6 }">
				<a-row>
					<a-col :span="13" v-if="isShowDetail">
						<a-form-item name="assetsId" label="盘亏资产">
							<!-- <assetsSelect v-model:value="formModal.assetsId" @change="onAssetsChange" placeholder="请选择盘亏资产">
								<template #value>
									{{ showFormModal.name }}
								</template>
							</assetsSelect> -->
							<!-- <a-input v-model:value="formModal.name" @change="onAssetDetail" placeholder="请输入盘亏资产编号或资产名称"></a-input> -->
							{{formModal.name}}
						</a-form-item>
						
						
						<a-form-item name="cause" label="原因">
							<!-- <a-select v-model:value="formModal.cause" allow-clear style="width: 190px;">
								<a-select-option :value="1">盘亏</a-select-option>
							</a-select> -->
							<!-- <a-input v-model:value="formModal.cause" placeholder="盘亏" disabled></a-input> -->
							{{formModal.cause === 1? '盘亏': '' }}
						</a-form-item>
						
						<a-form-item name="voucherNo" label="会计凭证号">
							<!-- <a-input v-model:value="formModal.voucherNo" placeholder="请输入会计凭证号"></a-input> -->
							{{formModal.voucherNo}}
						</a-form-item>
						
						<a-form-item label="补充说明">
							<!-- <a-input v-model:value="formModal.explain" placeholder="请输入补充说明"></a-input> -->
							{{formModal.explain}}
						</a-form-item>
						
						<a-form-item name="fileList" label="申请资料">
							<!-- <filesUpload v-model:value="formModal.fileList"></filesUpload>
							<p>注：建议上传pdf格式的文件，大小不超过100M</p> -->
							<a v-if="formModal.fileList.length !== 0" target="_blank" :href="formModal.fileList[0]">查看资料</a>
						</a-form-item>
					</a-col>
					
					<a-col :span="13" v-else>
						<a-form-item label="盘亏资产" name="assetsId" required>
							<a-form-item-rest>
								<assetsSelect v-model:value="formModal.assetsId" @change="onAssetsChange" placeholder="请选择资产">
									<template #value>
										{{ showFormModal.name }}
									</template>
								</assetsSelect>
							</a-form-item-rest>
							<!-- <a-input v-model:value="formModal.name" @change="onAssetsChange" placeholder="请输入盘亏资产编号或资产名称"></a-input> -->
							<!-- {{showFormModal.name}} -->
						</a-form-item>
						
						<a-form-item label="理由" name="cause" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<!-- <a-input placeholder="请输入理由"></a-input> -->
							<a-select v-model:value="formModal.cause" allow-clear style="width: 190px;">
								<a-select-option :value="1">盘亏</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item label="会计凭证号" name="voucherNo" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="formModal.voucherNo" placeholder="请输入会计凭证号"></a-input>
						</a-form-item>
						
						<a-form-item label="补充说明">
							<a-input v-model:value="formModal.explain" placeholder="请输入补充说明"></a-input>
						</a-form-item>
						
						<a-form-item label="申请资料" name="fileList" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<filesUpload v-model:value="formModal.fileList"></filesUpload>
							<p>注：建议上传pdf格式的文件，大小不超过100M</p>
						</a-form-item>
					</a-col>
					<a-col :span="11">
						<div class="ui-box">
							<a-form-item label="资产编号" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.code}}
							</a-form-item>
							<a-form-item label="资产分类名称" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.classifyName}}
							</a-form-item>
							<a-form-item label="资产名称" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.name}}
							</a-form-item>
							<a-form-item label="取得方式" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.gainType}} -->
								<div v-if="showFormModal.gainType === 1">新购</div>
								<div v-if="showFormModal.gainType === 2">调拨</div>
								<div v-if="showFormModal.gainType === 3">接受捐赠</div>
								<div v-if="showFormModal.gainType === 4">自建</div>
								<div v-if="showFormModal.gainType === 5">置换</div>
								<div v-if="showFormModal.gainType === 6">盘盈</div>
								<div v-if="showFormModal.gainType === 7">自行研制</div>
								<div v-if="showFormModal.gainType === 8">其他</div>
							</a-form-item>
							<a-form-item label="取得日期" class="ui-result__item" :label-col="{ span: 9 }">
								{{transDateTime(showFormModal.gainTime)}}
							</a-form-item>
							<a-form-item label="设备用途" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.useType}} -->
								<div v-if="showFormModal.useType === 1">办公</div>
								<div v-if="showFormModal.useType === 2">教学</div>
								<div v-if="showFormModal.useType === 3">培训</div>
								<div v-if="showFormModal.useType === 4">执法</div>
								<div v-if="showFormModal.useType === 5">窗口</div>
								<div v-if="showFormModal.useType === 6">专用</div>
								<div v-if="showFormModal.useType === 7">其他</div>
								<div v-if="showFormModal.useType === 8">医疗</div>
								<div v-if="showFormModal.useType === 9">科研</div>
							</a-form-item>
							<a-form-item label="规格型号" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.model}}
							</a-form-item>
							<a-form-item label="价值(元)" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.amount}}
							</a-form-item>
							<a-form-item label="资产照片" class="ui-result__item" :label-col="{ span: 9 }">
								<!-- {{showFormModal.imgUrl}} -->
								<a-image :width="100" v-for="(item,index) in showFormModal.imgList" :key="index"  :src="item"></a-image>
							</a-form-item>
							<a-form-item label="使用部门" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.departmentName}}
							</a-form-item>
							<a-form-item label="使用人" class="ui-result__item" :label-col="{ span: 9 }">
								{{showFormModal.user}}
							</a-form-item>
						</div>
					</a-col>
				</a-row>
			</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import { applyKeepAccounts, getAssetsDetailSearch } from '@/service/modules/property.js';
	import { lossOrProfitDetail, getAssetsDetail } from '@/service/modules/inventory.js';
	import assetsSelect from '@/components/assetsSelect/index.vue';
	import filesUpload from '@/components/upload/filesUpload.vue';
	import modalHeader from '@/components/modalHeader/index.vue';
	export default {
		components: { filesUpload, modalHeader, assetsSelect },
		data() {
			return {
				loading: false,
				visible: false,
				assetsIds: [],
				isShowDetail: true,
				assetsListId: null, // 从列表页进来时获得id
				formModal: {
					fileList: []
				},
				showFormModal: {},
				fileList: []
			}
		},
		methods: {
			// onAssetDetail(e) { // 输入框改变 发送获取详情请求
			// 	let tempItem
			// 	setTimeout(()=>{
			// 		tempItem = e.target._value
			// 		this.getData(tempItem)
			// 	},0)
			// },
			onAssetsChange(assetsData) {
				if(assetsData.length !== 0) {
					this.getData(assetsData[0].code).then(()=>{
						this.$refs.formRef.clearValidate();
					});
				}else{
					this.showFormModal = {
						imgList: []
					}
				}
				// this.getData(assetsData[0].code);
			},
			async getData(item) {
				this.loading = true;
				try {
					let ret = await getAssetsDetailSearch({
						nameOrCode: item
					});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.assetsId = [ret.data.id];
						this.showFormModal = ret.data;
						// this.showFormModal.imgList = []
						this.showFormModal.imgList = ret.data.imgUrl ? ret.data.imgUrl.split(','): [];
						// let tempStr = ret.data.imgUrl
						// let tempArr = tempStr? tempStr.split(','): []
						// this.showFormModal.imgList.push(...tempArr)
						// this.formModal.assetsId = ret.data.assets.id
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAssetsDetailFunc(id) { // 任务信息
				this.loading = true;
				try {
					let ret = await getAssetsDetail({
						id
					});
					this.loading = false;
					if (ret.code === 200) {
						// console.log("sear111",ret)
						this.showFormModal = ret.data;
						this.showFormModal.imgList = ret.data.imgUrl ? ret.data.imgUrl.split(','): [];
						// let tempStr = ret.data.imgUrl
						// let tempArr = tempStr? tempStr.split(','): []
						// this.showFormModal.imgList.push(...tempArr)
						// this.formModal.assetsId = ret.data.assets.id
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getDataDetail(id) {
				this.loading = true;
				try {
					let ret = await lossOrProfitDetail({
						id: id - 0
					});
					this.loading = false;
					if (ret.code === 200) {
						this.formModal.name = ret.data.assets.name,
						this.formModal.cause = ret.data.cause,
						this.formModal.voucherNo = ret.data.voucherNo,
						this.assetsListId = ret.data.assets.id
						this.formModal.explain = ret.data.explain,
						this.formModal.fileList = ret.data.materialUrl ? ret.data.materialUrl.split(',') : [];
						this.showFormModal = ret.data.assets;
						this.showFormModal.imgList = ret.data.imgUrl ? ret.data.imgUrl.split(','): [];
						// this.showFormModal.imgList = []
						// let tempStr = ret.data.assets.imgUrl
						// let tempArr = tempStr? tempStr.split(','): []
						// this.showFormModal.imgList.push(...tempArr)
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = this.$deepClone(this.formModal)
					// console.log("test11",postData)
					if(this.assetsListId !== null) {
						postData.assetsId = postData.assetsListId
					} else {
						let tempIds = postData.assetsId
						postData.assetsId = tempIds[0]
					}
					postData.type = 4 // 下账 写死
					postData.materialUrl = this.formModal.fileList.join(',');
					// console.log('url',this.fileList[0])
					postData = JSON.parse(JSON.stringify(postData));
					// console.log("test",postData)
					this.loading = true;
					try {
						let ret = await applyKeepAccounts(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('申请成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open(e,str) {
				// console.log('eee',e)
				if (e && e.id) {
						this.formModal.taskId = e.taskId || e.skipId
						// this.formModal.name = e.name;
						this.formModal.assetsId = [e.id] || [e.assetsRecordId] || [e.skipId];
					if(e.status == 1) { // 已下账 只做展示
						this.getDataDetail(e.assetsRecordId ||e.skipId)
						this.isShowDetail = true
					} else if(e.status == 0) {
						// let tempParam = [{code:e.code}]
						// console.log('tempParam',tempParam)
						// this.onAssetsChange(tempParam) // 回显左边的盘亏资产
						this.getAssetsDetailFunc(e.id ||e.skipId)
						this.isShowDetail = false
					}
				} else {
					this.isShowDetail = false
					this.showFormModal = {}
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			close() {
				this.isShowDetail = true
				this.$refs.formRef.clearValidate();
				this.assetsIds = [];
				this.formModal = {
					fileList: []
				}
				this.showFormModal = {
					imgList: []
				},
				this.visible = false;
			}
		}
	}
</script>

<style scoped>
	.ui-box {
		width: 300px;
		padding: 10px;
		margin: 0 0 0 auto;
		background: #F5F7FA;
		border-radius: 10px;
	}
	.ui-result__item {
		margin-bottom: 0;
	}
</style>