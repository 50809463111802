<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="24" style="margin-bottom: 14px;">
					<a-row>
						<a-col :span="8" :xxl="10" :xl="10">
							<div class="ui-task">
								<span>当前盘点任务：</span>
								{{this.taskName}}
							</div>
						</a-col>
						<a-col :span="8" :xxl="7" :xl="7">
							<div class="ui-task">
								<span>盘点截止时间：{{transDateTime(this.expirationTime,1)}}</span>
							</div>
						</a-col>
						<a-col :span="8" :xxl="7" :xl="7">
							<div class="ui-task">
								<span>希望任务提交时间：{{transDateTime(this.expectationTime,1)}}</span>
							</div>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="16">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onRef">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="8" style="text-align: right;">
					<a-button type="primary" @click="onProfit">盘盈入库</a-button>
					<a-button v-permission="['pc_inventory_loss_apply']" type="primary" style="background: #49D4F2;border-color: #49D4F2;" @click="onLossApply">申请下账</a-button>
					<!-- <a-button @click="onResult">盘点结果</a-button> -->
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="负责人名称" class="ui-form__item">
						<a-input v-model:value="formModal.userName"></a-input>
					</a-form-item>
					<a-form-item label="所属部门" class="ui-form__item">
						<a-select v-model:value="formModal.departmentId" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<!-- <a-form-item v-model:value="formRef." label="是否已完成" class="ui-form__item">
						<a-select  allow-clear style="width: 190px;"></a-select>
					</a-form-item> -->
					<div style="text-align: center;">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch">搜索</a-button>
					</div>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox value="userName">责任人名称</a-checkbox>
							<a-checkbox value="phone">责任人电话</a-checkbox>
							<a-checkbox value="departmentName">所属部门</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<!-- <c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, row, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
					</template>
				</c-Table> -->
				
				<a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, row, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<profitModal ref="profitModalRef"></profitModal>
		<lossApplyModal ref="lossApplyModalRef"></lossApplyModal>
		<resultModal ref="resultModalRef"></resultModal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import profitModal from '@/views/inventory/components/profitModal.vue';
	import lossApplyModal from '@/views/inventory/components/lossApplyModal.vue';
	import resultModal from '@/views/inventory/components/resultModal.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import { getSearchListTask } from '@/service/modules/inventory.js'
	export default {
		components: { Icon, profitModal, lossApplyModal, resultModal, exportModal, cTable },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				unitTaskId: null, // 当前路由携带
				taskName: '', // 当前路由携带任务名 
				expirationTime: null, // 当前路由携带截至时间
				expectationTime: null, // 当前路由携带希望提交时间
				taskId: null,
				searchData: {},
				formModal: {},
				getDataFun: getSearchListTask,
				type: 'inventoryDispatchStrategy', // 导出类型
				role: 0, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				list: [],
				sortList: [],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '责任人名称',
					align: 'center',
					dataIndex: "userName"
				}, {
					title: '责任人电话',
					align: 'center',
					dataIndex: "phone"
				}, {
					title: '所属部门',
					align: 'center',
					dataIndex: "departmentName"
				}, {
					title: '应盘资产（件）',
					align: 'center',
					dataIndex: "num"
				}, {
					title: '已盘资产（件）',
					align: 'center',
					dataIndex: "alreadyNum"
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				}
			}
		},
		created() {
			this.formModal.taskId = Number(this.$route.query.id)
			this.formModal.unitTaskId = Number(this.$route.query.unitTaskId)
			this.taskName = this.$route.query.name
			// this.expirationTime = this.$route.query.expirationTime
			// this.expectationTime = this.$route.query.expectationTime == 0? this.$route.query.expirationTime : this.$route.query.expectationTime
			// console.log("this.expectationTime",this.expectationTime )
			this.onSearch()
		},
		methods: {
			onRef() {
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getSearchListTask({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					// taskId: this.taskId,
					// unitTaskId: this.unitTaskId,
					...this.searchData
				})
				this.loading = false;
				if(ret.code === 200){
					this.list = ret.data.list.list;
					this.pagination.total = ret.data.totalCount;
					this.taskName = ret.data.taskName
					this.expirationTime = ret.data.expirationTime;
					this.expectationTime = ret.data.expectationTime == 0 ? ret.data.expirationTime : ret.data.expectationTime
				}
				} catch (error) {
					this.loading = false;
				}

			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				this.pagination.current = 1;
				// console.log("sear", this.searchData,this.formModal.name)
				this.getData();
				// this.$nextTick(() => {
				// 	this.$refs.cTable.toQuery();
				// })
			},
			onReset() {
				this.formModal = {}
				this.formModal.unitTaskId = Number(this.$route.query.unitTaskId)
				this.formModal.taskId = Number(this.$route.query.id)
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onProfit() {
				this.$refs.profitModalRef.open();
			},
			onExport() {
				// this.$refs.resultModalRef.open()
			},
			onLossApply() {
				this.$refs.lossApplyModalRef.open();
			},
			onResult() {
				this.$refs.resultModalRef.open();
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-task {
		font-size: 16px;
		font-weight: 500;
	}
	.ui-task span {
		color: #666666;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>